import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { Criteria, PageData, RequestUtilityService } from './utility/request-utility.service';
import { LRUCache } from 'typescript-lru-cache';
import { environment } from 'src/environments/environment';
const DEFAULT_LOOKUP_PAGE_SIZE = 50;
const cacheOptions = {
  maxSize: 500,
  entryExpirationTimeInMS: 60 * 1000 * 30,
};

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  cache: LRUCache;
  api_url: string;
  def_url: string;
  flink_url:string;
  accessToken: string = '';
  headers: any;
  env = environment;
  public myCriteria: Criteria = {
    sorters: [{ direction: "DESC", property: "id" }],
    pager: { pageNumber: 0, pageSize: 50 }
  };

  constructor(
    private http: HttpClient,
    private requestUtility: RequestUtilityService
  ) {
    this.api_url = this.env.API_DATA_URL;
    this.def_url = this.env.API_DEFINITION_URL;
    this.flink_url=this.env.API_FLINK_URL;
    this.cache = new LRUCache(cacheOptions);
  }

  public getEntitesByIds(id: any, criteria?: any) {
    const vIndex = criteria.pager.pageNumber + 1;
    return this.http.post<any[]>(`${this.api_url}/entity/${id}/data/filter?version=${environment.VERSION}&v=${vIndex}`, !!criteria ? criteria : this.myCriteria);
  }

  public getEntityByModelId(id: any) {
    return this.http.get(`${this.def_url}/models/${id}/entities`);
  }

  public getAllModels() {
    return this.http.get(`${this.def_url}/models`);
  }

  public load(id: number) {
    return this.http.get(`${this.def_url}/entities/${id}`);
  }

  public fetchStockingData(partNumbers: string[]): Observable<Record<string, { values: { suggested_stocking_segment: string; suggested_stocking_cert: string; }[] }>> {
    const payload = {
      version: "2.0",
      payload: partNumbers.map(part_number => ({ part_number })),
    };
  
    // Call the API and transform the response
    return this.http.post<any[]>(this.flink_url, payload).pipe(
      map((apiResponse: any[]) =>
        apiResponse.reduce((acc, entry) => {
          acc[entry.part_number] = {
            values: [
              {
                suggested_stocking_segment: entry.suggested_stocking_segment || '',
                suggested_stocking_cert: entry.suggested_stocking_cert || '',
              },
            ],
          };
          return acc;
        }, {} as Record<string, { values: { suggested_stocking_segment: string; suggested_stocking_cert: string; }[] }>),
      ),
      catchError(error => {
        console.error('Error fetching stocking data:', error);
        return throwError(() => error);
      }),
    );
  }



  public createEntityData(id: string | number, data: any): Observable<any> {
    return this.http.post(`${this.api_url}/entity/${id}/data`, {
      data: data,
    });
  }

  public updateEntityData(id: number, data: any)
  {
    return this.http.put(`${this.api_url}/entity/${id}/data`, {data: data});
  }

  public updateEntityDataINBulk(id: any, data: any)
  {
    return this.http.post(`${this.api_url}/entity/${id}/data/bulk?version=${environment.VERSION}`, data)
  }

  public getEntityDataCount(id: string | number,
    pageNumber: number,
    pageSize: number = DEFAULT_LOOKUP_PAGE_SIZE,
    filters: any,
  ): Observable<PageData> {
    const criteria: Criteria = {
      pager: {
        pageSize,
        pageNumber,
      },
      filters: filters,
    };
    const headers = new HttpHeaders().set("no-loader", "true");
    const url = this.requestUtility.addCriteria(
      `${this.api_url}/entity/${id}/data/count`,
      criteria
    );
    return this.http.get<PageData>(url);
    // let data = this.cache.get(url);
    // if (data) {
    //   return of(data);
    // } else {
    //   return this.requestUtility.get(url, headers).pipe(
    //     tap((items) => {
    //       this.cache.set(url, items);
    //     })
    //   );
    // }
  }

  public getValidationRules(id: any, data: any, vIndex: any)
  {
    return this.http.post(`${this.api_url}/entity/${id}/data/validateRules?version=${environment.VERSION}&v=${vIndex}`, {data: data})
  }

  public loadLookupData(
    id: string | number,
    pageNumber: number,
    pageSize: number = DEFAULT_LOOKUP_PAGE_SIZE,
    filters: any,
    sorters:any
  ): Observable<PageData> {
    const criteria: Criteria = {
      pager: {
        pageSize,
        pageNumber,
      },
      filters: filters,
      sorters:sorters
    };
    const headers = new HttpHeaders().set("no-loader", "true");
    const url = this.requestUtility.addCriteria(
      `${this.api_url}/entity/${id}/data/lookup?`,
      criteria,
      false
    );

    let data = this.cache.get(url);
    if (data) {
      return of(data);
    } else {
      return this.requestUtility.get(url, headers).pipe(
        tap((items) => {
          this.cache.set(url, items);
        })
      );
    }
    // return this.requestUtility.get(url, headers);
  }

  clearCache()
  {
    this.cache.clear();
  }
}
